const theme = {
  breakpoints: ["768px", "992px", "1200px"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512, 1024],
  fontSize: {
    mobile: [16, 22],
    tablet: [18, 28],
    desktop: [22, 34],
    h1: {
      mobile: 46,
      tablet: 58,
      desktop: 72,
    },
    h2: {
      mobile: 28,
      tablet: 35,
      desktop: 44,
    }
  }
};

export default theme;
