import React, { useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
// import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";
import theme from "../theme";
import Fab from "@mui/material/Fab";
import Slide from "@mui/material/Slide";
import { height, width } from "styled-system";
import { createTheme, ThemeProvider } from "@mui/material";
import { Carousel } from "antd";
// import { ForwardOutlined, BackwardOutlined } from "@ant-design/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: parseInt(theme["breakpoints"][0].replace("px", "")),
      md: parseInt(theme["breakpoints"][1].replace("px", "")),
      lg: parseInt(theme["breakpoints"][2].replace("px", "")),
      xl: 1536,
    },
  },
  palette: {
    brentblue: {
      main: "#6b89ca",
      contrastText: "white",
    },
  },
  typography: {
    fontFamily: `Roboto, sans-serif`,
  },
});

const style = {
  margin: 0,
  top: "auto",
  right: "auto",
  bottom: 15,
  left: 20,
  position: "fixed",
  color: "#6b89ca",
  width: ["175px", "250px", "275px", "350px"],
  fontSize: [
    theme["fontSize"]["mobile"][0] - 4,
    theme["fontSize"]["tablet"][0],
    theme["fontSize"]["tablet"][0],
    theme["fontSize"]["desktop"][0],
  ],
  fontWeight: "default",
};


const CarouselWrapper = styled(Carousel)`

  ${height};

  .slick-list {
    height: 100%;
  };
  .slick-track {
    height: 100%;
  };

  ${'' /* .slick-prev,
  .slick-next {
    width: 50px;
    height: 50px;
    z-index: 1000;
  }

  .slick-prev::after,
  .slick-next::after {
    display: none; /* Hide default arrows */
  }

  .custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 32px;
    color: black;
    background: gray;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    z-index: 1000;
  }
`;

const VideoContainer = styled.iframe`
  ${width};
  ${height};
`;

const VideoModal = ({ videoIds, title }) => {

  const totalVideos = videoIds.length

  const ref = useRef()

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setActiveIndex(0);
    setOpen(false);
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const goNextSlide = (ref) => {
    // if current is last video, and user presses next, go to the first
    if (ref.current.innerSlider.state.currentSlide === totalVideos - 1) {
      ref.current.goTo(0)
    } else {
      ref.current.next()
    }
  };

  const goPrevSlide = (ref) => {
    // if current is first video, and user presses prev, go to last
    if (ref.current.innerSlider.state.currentSlide === 0) {
      ref.current.goTo(totalVideos - 1)
    } else {
      ref.current.prev()
    }
  };

  const changeVideo = (currentVideoNum) => {
    setActiveIndex(currentVideoNum);
  };

  // const CustomPrevArrow = (props) => {
  //   const { className, style, onClick } = props;
  //   return (
  //     <BackwardOutlined
  //       className={`custom-arrow prev-arrow ${className}`}
  //       onClick={onClick}
  //     />
  //     // <span
  //     //   className={`custom-arrow prev-arrow ${className}`}
  //     //   onClick={onClick}
  //     // >
  //     //   <svg
  //     //     viewBox="0 0 1024 1024"
  //     //     focusable="false"
  //     //     data-icon="backward"
  //     //     width="auto"
  //     //     height="auto"
  //     //     fill="currentColor"
  //     //     aria-hidden="true"
  //     //   >
  //     //     <path d="M485.6 249.9L198.2 498c-8.3 7.1-8.3 20.8 0 27.9l287.4 248.2c10.7 9.2 26.4.9 26.4-14V263.8c0-14.8-15.7-23.2-26.4-13.9zm320 0L518.2 498a18.6 18.6 0 00-6.2 14c0 5.2 2.1 10.4 6.2 14l287.4 248.2c10.7 9.2 26.4.9 26.4-14V263.8c0-14.8-15.7-23.2-26.4-13.9z"></path>
  //     //   </svg>
  //     // </span>
  //   );
  // };

  // const CustomNextArrow = (props) => {
  //   const { className, style, onClick } = props;
  //   return (
  //     <ForwardOutlined
  //       className={`custom-arrow next-arrow ${className}`}
  //       onClick={onClick}
  //     />
  //   );
  // };

  return (
    <React.Fragment>
      <ThemeProvider theme={muiTheme}>
        <Fab onClick={() => setOpen(true)} variant="extended" sx={style}>
          {`Patient Testimonial!`}
        </Fab>

        <Dialog
          TransitionComponent={Transition}
          open={open}
          onClose={handleClose}
        >
          {/* <DialogTitle id="alert-dialog-title" align="center">
            {`${title}`}
          </DialogTitle> */}
          {open && (
          <DialogContent >
            <CarouselWrapper
              // arrows
              infinite={false}
              ref={ref}
              afterChange={changeVideo}
              // prevArrow={<CustomPrevArrow />}
              // nextArrow={<CustomNextArrow />}
              // height={["300px", "550px", "550px", "550px"]}
            >
              {videoIds.map((videoId, index) => (
                <>
                  {activeIndex === index ? (
                    <VideoContainer
                      key={index}
                      width="100%"
                      height={["300px", "550px", "550px", "550px"]}
                      src={`https://www.youtube.com/embed/${videoId}?rel=0&autoplay=1&controls=1"`}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture"
                      allowFullScreen
                      className="rounded-t-lg"
                    ></VideoContainer>
                  ) : null}
                </>
              ))}
            </CarouselWrapper>
          </DialogContent>
          )}
          <DialogActions
            sx={{
              "padding": "24px",
              "padding-top": "0px",
              "display": "flex",
              "justify-content": "space-between",
            }}
          >
          <Button
              onClick={() => {goPrevSlide(ref)}}
              color="brentblue"
              variant="contained"
            >
              Prev
            </Button>
            <Button
              href="/resources/reviews"
              color="brentblue"
              variant="contained"
              sx={{padding: "6px 14px"}}
            >
              See more
            </Button>
            <Button
              onClick={() => {goNextSlide(ref)}}
              color="brentblue"
              variant="contained"
            >
              Next
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default VideoModal;
